
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ReportItemRenderData } from "@/services/types/reports";
import { getStore } from "@/store/getStore";
import { groupBy } from "lodash";
import { getArrayAverage, roundToTwo } from "@/services/utils";
import { getRatingColor } from "@/services/reports/reportsUtils";
import RatingColorBar from "@/components/ui/RatingColorBar.vue";

@Component({
  methods: { getRatingColor },
  components: { RatingColorBar },
})
export default class Reports2TableRow extends Vue {
  @Prop() report: ReportItemRenderData;

  get averagesPerProject(): { [key: number]: number } {
    const reportEstimations = getStore().getters.getReports2RawData.filter(
      (v) => v.id === this.report.reportId
    )[0].estimations;

    const estimationsBySelectedProjects = reportEstimations
      .flatMap((i) => i.estimation)
      .filter((i) =>
        getStore().getters.getReports2SelectedProjects.includes(i.projectId)
      );
    const groupedEstimations = groupBy(
      estimationsBySelectedProjects,
      (i) => i.projectId
    );

    return Object.entries(groupedEstimations).reduce((acc, [key, value]) => {
      const avg = getArrayAverage(value.map((i) => i.value));
      acc[Number(key)] = avg !== null ? roundToTwo(avg) : null;
      return acc;
    }, {});
  }

  @Emit()
  showDetails() {
    return this.report.reportId;
  }
}
