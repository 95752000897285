
import { Vue, Component } from "vue-property-decorator";
import Reports2TableHeader from "@/components/reports2/table/Reports2TableHeader.vue";
import { getStore } from "@/store/getStore";
import Reports2TableRow from "@/components/reports2/table/Reports2TableRow.vue";
import Reports2TableRowDetails from "@/components/reports2/table/Reports2TableRowDetails.vue";

@Component({
  components: {
    Reports2TableRowDetails,
    Reports2TableRow,
    Reports2TableHeader,
  },
  watch: {
    reportsList: "reportsListUpdateHandler",
  },
})
export default class Reports2Table extends Vue {
  selectedReportId: number = null;

  get reportsList() {
    return getStore().getters.getReports2RenderData.filter((r) =>
      getStore().getters.getReports2SelectedReports.includes(r.reportId)
    );
  }

  setSelectedReportId(id: number) {
    this.selectedReportId = id;
  }

  reportsListUpdateHandler() {
    this.selectedReportId = null;
  }
}
