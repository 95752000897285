import writeXlsxFile, { Row, SheetData } from "write-excel-file";

export async function buildExcelFromDictionary(
  data: { key: string; value: string }[],
  filename: string
) {
  const sheetData: SheetData = data.map((i) => {
    return [
      {
        value: i.key,
        type: String,
      },
      {
        value: i.value,
        type: String,
      },
    ] as Row;
  });

  await writeXlsxFile(sheetData, {
    fileName: `${filename}.xlsx`,
  });
}
