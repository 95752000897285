
import { Component, Vue } from "vue-property-decorator";
import { Bar } from "vue-chartjs";
import {
  CHART_NO_DATA,
  getBarChartData,
} from "@/services/reports/reportsUtils";
import { getStore } from "@/store/getStore";
import Chart, { Chart as ChartJS, Tooltip, LinearScale } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(Tooltip, LinearScale, annotationPlugin);

@Component({
  components: {
    Bar,
  },
  watch: {
    chartTargets: "handleChartTargetsUpdate",
  },
})
export default class Reports2BarChart extends Vue {
  chartOptions = {
    indexAxis: "x",
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {},
      },
      title: {
        display: false,
      },
      tooltip: {
        position: "nearest",
        enabled: true,
        backgroundColor: "#F3F5F7",
        cornerRadius: 4,
        bodyColor: "#343A40",
        bodyFont: {
          family: "Roboto",
        },
        caretSize: 0,
        callbacks: {
          label: (tooltipItem) => {
            return tooltipItem
              ? `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`
              : "";
          },
          title: () => null,
        },
      },
    },
    scales: {
      x: {
        display: false,
        stacked: true,
      },
      y: {
        min: 0,
        max: 100,
        stacked: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  defaultAnnotationLine = {
    type: "line",
    yMin: 0,
    yMax: 0,
    borderColor: "#DC3545",
    borderWidth: 2,
    borderDash: [10, 5],
  };

  chartStyles = {
    height: `500px`,
    position: "relative",
  };

  get chartData() {
    const data = getBarChartData(
      getStore().getters.getReports2RenderData,
      getStore().getters.getReports2SelectedReports
    );

    return data.datasets.length ? data : CHART_NO_DATA;
  }

  get chartTargets() {
    const targets = {};
    for (const [key, target] of Object.entries(
      getStore().getters.getReports2Targets
    )) {
      targets[`line${key}`] = {
        ...this.defaultAnnotationLine,
        yMin: target.value,
        yMax: target.value,
        borderColor: target.color,
        label: {
          display: true,
          position: "end",
          content: key,
          color: "#fff",
          backgroundColor: target.color,
        },
      };
    }

    return targets;
  }

  mounted() {
    this.handleChartTargetsUpdate();
  }

  handleChartTargetsUpdate() {
    const chart: Chart.Chart = (this.$refs.chartRef as any).chart;
    chart.options.plugins.annotation.annotations = this.chartTargets;
  }
}
