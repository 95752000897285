
import { Vue, Component } from "vue-property-decorator";
import { StoreMutationTypes } from "@/store/types";
import { EXTENDED_COLOR_PALETTE } from "@/store/modules/reports/helpers";
import { getStore } from "@/store/getStore";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getRandomInt } from "@/services/utils";

@Component({
  components: {},
})
export default class Reports2GraphTargets extends Vue {
  trashIcon = faTrash;

  get targets() {
    return getStore().getters.getReports2Targets;
  }

  removeChartTarget(key: string) {
    if (!this.targets[key]) {
      return;
    }

    const targets = JSON.parse(JSON.stringify(this.targets));
    delete targets[key];

    this.$store.commit(StoreMutationTypes.SET_REPORTS2_TARGETS, targets);
  }

  setChartTarget(targetName: string, target: number) {
    if (this.targets[targetName]) {
      return false;
    }
    const color = this.getRandomColor();
    this.$store.commit(StoreMutationTypes.SET_REPORTS2_TARGETS, {
      ...this.targets,
      [targetName]: {
        value: target,
        color: color,
      },
    });

    return true;
  }

  openAddTargetModal() {
    (this.$root as any).openDialog({
      component: {
        is: () =>
          import("@/components/reports2/modals/Reports2AddTargetModal.vue"),
        _name: "AddTarget",
        action: this.setChartTarget,
      },
      settings: {
        position: "center",
      },
    });
  }

  getRandomColor() {
    return EXTENDED_COLOR_PALETTE[
      getRandomInt(0, EXTENDED_COLOR_PALETTE.length)
    ];
  }
}
