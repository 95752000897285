
import { Component, Vue } from "vue-property-decorator";
import Reports2Header from "@/components/reports2/header/Reports2Header.vue";
import Reports2Filters from "@/components/reports2/header/Reports2Filters.vue";
import Reports2Graph from "@/components/reports2/graph/Reports2Graph.vue";
import Reports2Table from "@/components/reports2/table/Reports2Table.vue";
import { getStore } from "@/store/getStore";
import { StoreActionTypes } from "@/store/types";
import router from "@/router";

@Component({
  components: {
    Reports2Table,
    Reports2Graph,
    Reports2Filters,
    Reports2Header,
  },
})
export default class Reports2 extends Vue {
  async mounted() {
    const searchParams: { callId?: number } = decodeURI(window.location.search)
      .replace("?", "")
      .split("&")
      .map((param) => param.split("="))
      .reduce((values, [key, value]) => {
        values[key] = value;
        return values;
      }, {});
    if (searchParams.callId) {
      router
        .push({
          path: "calls",
          query: { callId: searchParams.callId.toString() },
        })
        .catch((e) => {
          throw e;
        });
    }
    await getStore().dispatch(StoreActionTypes.FETCH_REPORTS2_DATA, null);
  }
}
