
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Reports2Header extends Vue {
  async exportChart(type = "pdf") {
    throw new Error("Not implemented");
  }
}
