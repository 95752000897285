var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"Reports2TableRowDetails"},[(_vm.isLoading)?_c('div',{staticClass:"loader"},[_c('b-spinner',{staticStyle:{"width":"5rem","height":"5rem"},attrs:{"variant":"primary"}})],1):[_c('div',{staticClass:"left"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"row-element name"},[_c('p',[_vm._v(_vm._s(_vm.report.name))])]),_c('div',{staticClass:"row-element estimation"},[_c('p',[_vm._v(_vm._s(_vm.report.totalAvgRating))])])]),_c('div',{staticClass:"criterions"},_vm._l((_vm.renderData),function(c){return _c('div',{key:c.reportId,staticClass:"criterion",class:{
            active: c.reportId === _vm.selectedReportId,
          },on:{"click":function($event){return _vm.setSelectedReport(c.reportId)}}},[_c('div',{staticClass:"name",attrs:{"title":c.name}},[_c('div',{staticClass:"radio"},[(c.reportId === _vm.selectedReportId)?_c('div',{staticClass:"inner"}):_vm._e()]),_c('p',[_vm._v(_vm._s(c.name))])]),_c('p',{staticClass:"rating"},[_vm._v(_vm._s(c.totalAvgRating))]),_c('RatingColorBar',{staticStyle:{"margin-right":"4px","width":"70%"},attrs:{"rating":c.totalAvgRating}})],1)}),0)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"right-table"},[_vm._m(0),(_vm.callsIsLoading)?_c('div',{staticClass:"loader"},[_c('b-spinner',{staticStyle:{"width":"5rem","height":"5rem"},attrs:{"variant":"primary"}})],1):[(!_vm.filteredReportCalls.length)?_c('div',{staticClass:"no-data"},[_c('span',[_vm._v("Нет данных")])]):_vm._l((_vm.filteredReportCalls),function(call,idx){return _c('div',{key:`${call.id}-${call.project.id}-${idx}`,staticClass:"right-table-row",on:{"click":function($event){return _vm.showCallDetails(call.id)}}},[(_vm.playingCallId === call.id && _vm.playingCallIndex === idx)?_c('PlayerComponent',{staticStyle:{"display":"none"}}):_vm._e(),_c('div',{staticClass:"right-table-row-element play"},[_c('div',{staticClass:"play-btn",on:{"click":function($event){$event.stopPropagation();return _vm.playCall(call.id, idx)}}},[(
                      _vm.playingCallId === call.id &&
                      _vm.playingCallIndex === idx &&
                      _vm.isPlayerLoading
                    )?_c('b-spinner'):_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":_vm.getPlayerIcon(call.id, idx)}})],1)]),_c('div',{staticClass:"right-table-row-element name"},[_c('p',[_vm._v(_vm._s(call.project.name))])]),_c('div',{staticClass:"right-table-row-element date"},[_c('p',[_vm._v(_vm._s(_vm.formatCallDate(call.updatedAt)))])]),_c('div',{staticClass:"right-table-row-element duration"},[_c('p',[_vm._v(_vm._s(_vm.formatCallDuration(call.duration)))])]),_c('div',{staticClass:"right-table-row-element direction"},[_c('p',[_vm._v(_vm._s(_vm.$t(`utils.call.${call.direction}`)))])]),_c('div',{staticClass:"right-table-row-element estimation"},[_c('p',{staticStyle:{"margin-right":"8px","min-width":"40px"}},[_vm._v(" "+_vm._s(call.estimation.value)+" ")]),_c('RatingColorBar',{staticClass:"mr-1",attrs:{"rating":parseFloat(call.estimation.rating),"type":"point"}})],1),_c('div',{staticClass:"right-table-row-element weight"},[_c('p',[_vm._v(_vm._s(call.optionValue))])]),_c('div',{staticClass:"right-table-row-element comment"},[_c('p',[_vm._v(_vm._s(call.phone))])])],1)})]],2)])],_c('button',{staticClass:"flow-button",on:{"click":function($event){return _vm.$emit('close')}}},[_c('EIcon',{attrs:{"name":"chevron-down-thin","width":"10px"}})],1),_c('b-overlay',{class:`GridComponent__overlay`,attrs:{"show":_vm.callDetailsShow,"no-wrap":"","fixed":"","no-center":"","opacity":"0.5"},on:{"click":_vm.handleOverlayClick},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"overlay-wrapper"},[_c('CallsDetails',{ref:"callsDetailsRef",attrs:{"close-listener":() => {}},on:{"close-btn-click":() => {
              _vm.callDetailsShow = false;
            }}})],1)]},proxy:true}])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"right-table-header"},[_c('p'),_c('p',[_vm._v("Проект")]),_c('p',[_vm._v("Дата звонка")]),_c('p',[_vm._v("Длительность")]),_c('p',[_vm._v("Направление")]),_c('p',[_vm._v("Оценка")]),_c('p',[_vm._v("Вес")]),_c('p',[_vm._v("Номер клиента")])])
}]

export { render, staticRenderFns }