
import { Component, Vue } from "vue-property-decorator";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getStore } from "@/store/getStore";
import { StoreActionTypes } from "@/store/types";
import { ReportsSnap } from "@/store/modules/reports2/types";
import {
  applyReportsSnap,
  createReportsSnap,
} from "@/services/reports/reportsSnaps";

@Component({
  components: {},
})
export default class Reports2SavedFiltersPanel extends Vue {
  deleteIcon = faTrash;
  editIcon = faPen;
  createIcon = faPlus;

  get savedFilters(): ReportsSnap[] {
    return getStore().getters.getSavedFilters?.reportsSnaps ?? [];
  }

  get selectedReportsSnapId() {
    return getStore().getters.getReports2SelectedReportsSnapId;
  }

  async mounted() {
    await getStore().dispatch(StoreActionTypes.GET_SAVED_FILTERS, null);
  }

  openCreateFilterModal() {
    (this.$root as any).openDialog({
      component: {
        is: () =>
          import(
            "@/components/reports2/modals/Reports2EditSavedFilterModal.vue"
          ),
        _name: "Reports2EditSavedFilterModal",
        action: (name) => this.createNewFilter(name),
        title: `Сохранить новый фильтр`,
      },
      settings: {
        position: "center",
        zIndex: 121,
      },
    });
  }

  openEditFilterModal(name: string, id: string) {
    (this.$root as any).openDialog({
      component: {
        is: () =>
          import(
            "@/components/reports2/modals/Reports2EditSavedFilterModal.vue"
          ),
        _name: "Reports2EditSavedFilterModal",
        action: (name, id) => this.editFilter(name, id),
        savedName: name,
        savedId: id,
        title: `Перезаписать фильтр`,
      },
      settings: {
        position: "center",
        zIndex: 121,
      },
    });
  }

  async createNewFilter(name: string) {
    const snap = createReportsSnap(name);

    const filters = getStore().getters.getSavedFilters;
    if (filters.reportsSnaps) {
      filters.reportsSnaps.push(snap);
    } else {
      filters.reportsSnaps = [snap];
    }

    await this.$store.dispatch(StoreActionTypes.SET_SAVED_FILTERS, filters);
  }

  async editFilter(newName: string, id: string) {
    const index = this.savedFilters.findIndex((f) => f.id === id);
    if (index === -1) {
      return (window as any).error("Такой фильтр не существует");
    }

    const newFilter = createReportsSnap(newName);
    newFilter.id = this.savedFilters[index].id;
    this.savedFilters[index] = newFilter;
    await this.$store.dispatch(
      StoreActionTypes.SET_SAVED_FILTERS,
      getStore().getters.getSavedFilters
    );
  }

  async removeFilter(id: string) {
    const index = this.savedFilters.findIndex((f) => f.id === id);
    if (index === -1) {
      return (window as any).error("Такой фильтр не существует");
    }
    this.savedFilters.splice(index, 1);
    await this.$store.dispatch(
      StoreActionTypes.SET_SAVED_FILTERS,
      getStore().getters.getSavedFilters
    );
  }

  async applyFilter(id: string) {
    const filter = this.savedFilters.find((f) => f.id === id);
    if (!filter) return (window as any).error("Такой фильтр не существует");
    await applyReportsSnap(filter);
  }
}
