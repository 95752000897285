
import { Component, Vue } from "vue-property-decorator";
import { getStore } from "@/store/getStore";
import {
  faArrowTrendUp,
  faChartBar,
  faChartSimple,
  faTrash,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import * as helpers from "@/store/modules/reports/helpers";
import _ from "lodash";
import Reports2BarChart from "@/components/reports2/graph/Reports2BarChart.vue";
import Reports2LineChart from "@/components/reports2/graph/Reports2LineChart.vue";
import Reports2HorizontalBarChart from "@/components/reports2/graph/Reports2HorizontalBarChart.vue";
import Reports2GraphReportsList from "@/components/reports2/graph/Reports2GraphReportsList.vue";
import Reports2GraphTargets from "@/components/reports2/graph/Reports2GraphTargets.vue";

@Component({
  components: {
    Reports2GraphTargets,
    Reports2GraphReportsList,
    Reports2HorizontalBarChart,
    Reports2LineChart,
    Reports2BarChart,
  },
  watch: {},
})
export default class Reports2Graph extends Vue {
  trashIcon: IconDefinition = faTrash;
  BarChartIcon: IconDefinition = faChartSimple;
  HorizontalBarChartIcon: IconDefinition = faChartBar;
  LineChartIcon: IconDefinition = faArrowTrendUp;

  chartType = "BarChart";
  chartTypes = [
    {
      type: "BarChart",
      icon: this.BarChartIcon,
    },
    {
      type: "LineChart",
      icon: this.LineChartIcon,
    },
    {
      type: "HorizontalBarChart",
      icon: this.HorizontalBarChartIcon,
    },
  ];
  roundType = "day";
  roundTypes = [
    {
      text: "дням",
      value: "day",
    },
    {
      text: "неделям",
      value: "week",
    },
    {
      text: "месяцам",
      value: "month",
    },
    {
      text: "годам",
      value: "year",
    },
  ];

  get targets() {
    return {};
  }

  get dataLoading(): boolean {
    return getStore().getters.getIsReports2DataLoading;
  }

  setChartType(type) {
    this.chartType = type;
  }

  setChartRoundType(type: string) {
    this.roundType = type;
  }
}
