
import { Vue, Component } from "vue-property-decorator";
import { getStore } from "@/store/getStore";

@Component({
  components: {},
})
export default class Reports2TableHeader extends Vue {
  get pageType() {
    return getStore().getters.getReports2Page;
  }

  get selectedProjects() {
    return getStore().getters.getReports2SelectedProjectsObjects;
  }
}
