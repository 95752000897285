
import { Vue, Component, Prop } from "vue-property-decorator";
import { getRatingColor } from "@/services/reports/reportsUtils";

@Component({
  methods: { getRatingColor },
  components: {},
})
export default class RatingColorBar extends Vue {
  @Prop() rating: number;
  @Prop({ default: "bar" }) type: "bar" | "point";
}
