
import { Component, Vue } from "vue-property-decorator";
import { getStore } from "@/store/getStore";
import { Reports2MutationTypes } from "@/store/modules/reports2/mutations/types";
import { without } from "lodash";
import Table from "@/components/ewa/table/Table.vue";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { buildExcelFromCardsList } from "@/services/cards2excel";
import { buildExcelFromDictionary } from "@/services/dictionary2excel";
import { showErrorMessage } from "@/services/ui/messages";

@Component({
  methods: {
    faFileExport() {
      return faFile;
    },
  },
  components: { Table },
  watch: {
    selected: "handleSelectedUpdate",
  },
})
export default class Reports2GraphReportsList extends Vue {
  allSelected = false;
  indeterminate = false;

  get selected() {
    return getStore().getters.getReports2SelectedReports;
  }

  get reportsRenderList() {
    return getStore().getters.getReports2RenderData.sort(
      (a, b) => b.totalAvgRating - a.totalAvgRating
    );
  }

  mounted() {
    this.handleSelectedUpdate(this.selected, this.selected);
  }

  handleToggleAll(checked: boolean) {
    if (checked) {
      getStore().commit(
        Reports2MutationTypes.SET_REPORTS2_SELECTED_REPORTS,
        getStore().getters.getReports2RenderData.map((r) => r.reportId)
      );
    } else {
      getStore().commit(
        Reports2MutationTypes.SET_REPORTS2_SELECTED_REPORTS,
        []
      );
    }
  }

  handleSelectedUpdate(newValue: number[], oldValue: number[]) {
    if (newValue.length === 0) {
      this.allSelected = false;
      this.indeterminate = false;
    } else if (newValue.length === this.reportsRenderList.length) {
      this.allSelected = true;
      this.indeterminate = false;
    } else {
      this.allSelected = false;
      this.indeterminate = true;
    }
  }

  handleChange(id: number) {
    const selected = getStore().getters.getReports2SelectedReports;
    if (this.isReportSelected(id)) {
      getStore().commit(
        Reports2MutationTypes.SET_REPORTS2_SELECTED_REPORTS,
        without(selected, id)
      );
    } else {
      getStore().commit(Reports2MutationTypes.SET_REPORTS2_SELECTED_REPORTS, [
        ...selected,
        id,
      ]);
    }
  }

  isReportSelected(id: number) {
    return getStore().getters.getReports2SelectedReports.includes(id);
  }

  async exportToExcel() {
    try {
      const data = this.reportsRenderList.map((i) => ({
        key: i.name.toString(),
        value: i.totalAvgRating.toString(),
      }));
      await buildExcelFromDictionary(data, `export`);
    } catch (e) {
      showErrorMessage("При попытке экспорта произошла ошибка.");
      console.error(e);
    }
  }
}
